import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

import './PostCard.css'

const PejabatCard = ({
  featuredimage,
  title,
  jabatan,
  es2,
  slug,
  categories = [],
  className = '',
  ...props
}) => (
  <Link to={slug} className={`PostCard ${className}`}>
      <div className="PostCard--Image-pejabat relative">
        {featuredimage &&
        <Img
          fluid={featuredimage.childImageSharp.fluid}
          imgStyle={{ objectFit: 'contain' }}
          alt={title} />
        }
      </div>
    <div className="PostCard--Content">
      <div className="PostCard--Category">
      <span className="subtitle is-size-6 is-block">
        {categories && categories.map(cat => cat.category).join(', ')}  {es2 && es2}
      </span>
      </div>
      {jabatan && <p className="PostCard--Excerpt-pejabat taCenter">{jabatan}</p>}
    </div>
  </Link>
)

export default PejabatCard