import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

import './PostCard.css'

const PejabatCard = ({
  featuredimage,
  title,
  jabatan,
  tanggalLahir,
  es2,
  slug,
  categories = [],
  className = '',
  ...props
}) => (
  <Link to={slug} className={`bdayCard ${className}`}>
        <div className="pyro">
                  <div className="before"></div>
                  <div className="after"></div>
        </div>
      <div className="PostCard--Image--bday relative">
        {featuredimage &&
        <Img
          fluid={featuredimage.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover', marginTop: -93 }}
          alt={title} />
        }
      </div>
    <div className="bdayCard--Content">
      {title && <p className="PostCard--Excerpt-pejabat taCenter">{title}</p>}
      <br />
      {tanggalLahir && <p className="PostCard--Excerpt-pejabat taCenter">{tanggalLahir.slice(0,10)}</p>}
      <div className="PostCard--Category">
        <span className="subtitle is-size-6 is-block">
          {categories && categories.map(cat => cat.category).join(', ')}  {es2 && es2}
        </span>
      </div>
    </div>
        
  </Link>
)

export default PejabatCard