import React from 'react'

import BdayCard from '../components/BdayCard'
import './PostSection.css'
import './bdaySection.css'

class PejabatSection extends React.Component {
  static defaultProps = {
    profil: [],
    title: '',
    limit: 16,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 16
  }

  state = {
    limit: this.props.limit
  }

  increaseLimit = () =>
    this.setState(prevState => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  render() {
    const { profil, title, showLoadMore, loadMoreTitle } = this.props,
      { limit } = this.state,
      visibleProfil = profil.slice(0, limit || profil.length)

    return (
      <>
      {!!profil.length && (
      <section className="section hbd">
        <div className="container">


            <div className="PostSection">
              <h2 className="ucapanbday">Selamat Ulang Tahun! 🎉</h2>
              {title && <h2 className="PostSection--Title">{title}</h2>}
              {!!visibleProfil.length && (
                <div className="bday-grid">
                  {visibleProfil.map((post, index) => (
                    <BdayCard key={post.title + index} {...post} />
                  ))}
                </div>
              )}

              {showLoadMore && visibleProfil.length < profil.length && (
                <div className="taCenter">
                  <button className="button" onClick={this.increaseLimit}>
                    {loadMoreTitle}
                  </button>
                  <br />
                </div>
              )}                
            </div>
        </div>
              <div>
                    <div class="candle">
                       <div id="flame" class="lit"></div>
                    </div>
                    <div class="cake"></div>
                  <div class="plate"></div>
              </div>
      </section>
      )}
      </>
    )
  }
}

export default PejabatSection
